import React from 'react'

const LinkStylesheets = () => (
    // This is a component that will encompass and house all of our stylesheet-like
    // imports into our base template files. Note that our styleshees should be imported
    // into both the blog-list as well as the blog-pages themselves.
    <>
        <link rel="stylesheet" href="animate.min.css" />
        <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
    </>
)

export default LinkStylesheets
