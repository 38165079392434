export const getAuth0Config = () => {
    const config = {
        domain: process.env.GATSBY_AUTH0_DOMAIN,
        clientId: process.env.GATSBY_AUTH0_CLIENTID,
        // audience: process.env.GATSBY_AUTH0_AUDIENCE,
    };

    const providerConfig = {
        ...config,
        redirectUri: typeof window !== 'undefined' && window?.location?.origin,
        // onRedirectCallback: window.location.pathname,
    };

    return providerConfig;
};
