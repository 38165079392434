import React from 'react';
import LogRocket from 'logrocket';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import { upsertSessionStorage } from '../components/upsertSessionStorage';

const LOG_ROCKET_SESSION_CACHE = 'LOG_ROCKET_SESSION_CACHE';

const useLogRocketHash = () => {
    const { user, isAuthenticated } = useAuth0();

    // first return an auth0 user.
    if (isAuthenticated) {
        return `${user.sub}-${user.email}`;
    }

    try {
        const newLogRocketSession = uuidv4();
        return upsertSessionStorage(
            LOG_ROCKET_SESSION_CACHE,
            newLogRocketSession
        );
    } catch (err) {
        console.error(err);
    }
};

const LogRocketIntegration = () => {
    const logRocketSession = useLogRocketHash();
    // TODO:
    // when we get __10 users__ signed up, then we'll refactor this
    // so that we track by user-info rather than an anonymous
    // uuid string
    LogRocket.init('eczkjs/thoughtfulcandles');
    if (logRocketSession) {
        LogRocket.identify(logRocketSession);
    }
    return null;
};

export default LogRocketIntegration;
