import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import LogRocketIntegration from '../integrations/LogRocketIntegration';
import { Auth0Provider } from '@auth0/auth0-react';
import { getAuth0Config } from '../components/LoginButton/helpers';

const queryClient = new QueryClient();

const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER,
};

const Base = (props) => {
    const isProduction = process.env.NODE_ENV === 'production';

    const providerConfig = getAuth0Config();
    return (
        <Auth0Provider {...providerConfig}>
            <AlertProvider template={AlertTemplate} {...options}>
                <QueryClientProvider client={queryClient}>
                    {isProduction && <LogRocketIntegration />}
                    <ReactQueryDevtools initialIsOpen={!isProduction} />
                    {props.children}
                </QueryClientProvider>
                <script
                    data-ad-client="ca-pub-7326174782110539"
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                ></script>
            </AlertProvider>
        </Auth0Provider>
    );
};

export default Base;
