/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
// FaTwitter
import { FaTwitter, FaInstagram } from 'react-icons/fa';
import { rhythm, scale } from '../utils/typography';

export const SocialHandles = () => (
    <>
        <FaTwitter /> <a href="https://twitter.com/brianczhou">@brianczhou</a> /{' '}
        <FaInstagram />{' '}
        <a href="https://www.instagram.com/thoughtfulcandlesnyc">
            @thoughtfulcandlesnyc
        </a>
    </>
);

const Bio = () => {
    // let's not include this -- it takes up unnecessary space
    // return null;
    return (
        <div>
            <p>
                <SocialHandles />
            </p>
        </div>
    );

    return (
        <div
            style={{
                marginBottom: rhythm(2.5),
                ...scale(0.01),
            }}
        >
            <p>
                I make tools for candlemaking enthusiasts, I publish thoughts
                around the practice, and I sometimes{' '}
                <a href="https://thoughtfulcandles.shop/">make candles.</a>
            </p>
        </div>
    );
};

export default Bio;
